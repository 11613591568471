<template>
  <div class="system">
    <Banner></Banner>
    <h2 class="h2">产品体系</h2>
    <div class="system_context">
      <div class="context_item" v-for="(conext, index) in contextList" :key="index">
        <div class="title">{{ conext.title }}</div>
        <div class="text">{{ conext.text }}</div>
        <template v-for="item in conext.children">
          <div class="item_title">{{ item.itemTitle }}</div>
          <div class="item_text" v-for="text in item.itemText">
            <img src="@/assets/img/pc/product/true.png" />
            <span>{{ text }}</span>
          </div>
        </template>
        <div class="btn" @click="torouter">申请试用</div>
      </div>
    </div>
    <FootMain></FootMain>
  </div>
</template>

<script>
import Banner from '@/components/pc/product/product.vue'
import FootMain from '@/components/pc/commom/footmain/index.vue'
export default {
  data() {
    return {
      contextList: [
        {
          title: '终端并发数（STA）',
          text: '系统所能支持的最大在线设备数量',
          children: [
            {
              itemTitle: '员工管理',
              itemText: ['授权开启', '终端限制及绑定', '带宽设定']
            },
            {
              itemTitle: '黑白名单',
              itemText: ['MAC白名单']
            },
            {
              itemTitle: '策略配置',
              itemText: ['上网时段', '上网时长', '带宽设定']
            }
          ]
        },
        {
          title: 'AC授权',
          text: '系统允许接入的无线控制器或网 关数量',
          children: [
            {
              itemTitle: '控制器管理',
              itemText: ['添加维护控制器', '认证方式开启']
            },
            {
              itemTitle: '认证模板',
              itemText: ['添加维护认证模板页']
            }
          ]
        },
        {
          title: '定制开发',
          text: '根据用户自身需求进行定制性开 发，最终费用由工作量决定',
          children: [
            {
              itemTitle: '认证方式',
              itemText: ['增加认证方式，包含数据源等', '兼容其他厂商设备', '对接第三方系统', '其他需求']
            },
            {
              itemTitle: '认证页面',
              itemText: ['需要对认证页个性化定制']
            }
          ]
        }
      ]
    }
  },
  components: {
    Banner,
    FootMain
  },
  methods: {
    torouter() {
      this.$router.push('/trial')
    }
  }
}
</script>

<style lang="scss" scoped>
h2 {
  margin: 1.315789rem 0 2.631578rem !important;
}

.system_context {
  width: 47.763157rem;
  // width: 47.631578rem;
  // height: 51.842105rem;
  margin: 0 auto;
  background-color: white;
  display: flex;
  justify-content: space-between;

  // overflow: hidden;
  .context_item {
    position: relative;
    // width: 15.368421rem;
    width: 15.263157rem;
    // height: 25.263157rem;
    height: 25.131578rem;
    background-color: white;
    box-shadow: 0px 0px 7px 4px #f1f1f1;
    border-radius: 0.526315rem;
    padding: 1.18421rem 0.921052rem;
    box-sizing: border-box;

    .title {
      font-size: 1.052631rem;
      color: #333;
      text-align: center;
      margin-bottom: 0.789473rem;
    }

    .text {
      font-size: 0.894736rem;
      color: #666;
      // line-height: 1.105263rem;
      // margin: 0.789473rem 0;
    }

    .item_title {
      font-size: 0.947368rem;
      color: #333;
      margin: 0.394736rem 0;
    }

    .item_text {
      img {
        height: 0.789473rem;
        margin-right: 0.526315rem;
      }
      font-size: 0.894736rem;
      color: #666;
      margin-bottom: 0.526315rem;
      line-height: 1.052631rem;
    }
  }

  .btn {
    width: 11.842105rem;
    height: 2.368421rem;
    box-sizing: border-box;
    border: 2px solid #59b839;
    position: absolute;
    bottom: 1.18421rem;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1.2rem;
    line-height: 2.263157rem;
    color: #66ba22;
    text-align: center;
    border-radius: 0.263157rem;
    cursor: pointer;
  }
}

.h2 {
  text-align: center;
  font-size: 1.578947rem;
  color: #333;
  margin: 0;

  &:before {
    display: inline-block;
    content: '';
    height: 0.263157rem;
    width: 5.526315rem;
    background-color: #11e323;
    vertical-align: middle;
    transform: translateY(-50%);
    margin: 0 0.394736rem;
  }

  &:after {
    display: inline-block;
    content: '';
    height: 0.263157rem;
    width: 5.526315rem;
    background-color: #11e323;
    vertical-align: middle;
    transform: translateY(-50%);
    margin: 0 0.394736rem;
  }
}
</style>
