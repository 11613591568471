<template>
  <div class="product_context" ref="bannerProductRef">
    <div class="banner">
      <div class="banner_context">
        <div class="shows">
          <div class="transverse"></div>
          <h2>网络统一准入系统</h2>
          <p class="context_text">针对身份不同，设置不同的上网权限</p>
          <p class="context_text">PC、移动端安全接入</p>
        </div>
        <div class="animation">
          <div class="context_img">
            <img src="@/assets/img/pc/product/win.png" />
            <p style="margin-right: 1.18421rem">Windows Server</p>
            <img src="@/assets/img/pc/product/linux.png" />
            <p>Linux CentOS</p>
          </div>
          <button class="btn" @click="download">立即下载</button>
        </div>
      </div>
      <div class="img">
        <img src="@/assets/img/pc/product/banner_img01.png" class="img01" />
        <img src="@/assets/img/pc/product/banner_img02.png" class="img02" />
      </div>
      <!-- <img src="@/assets/img/pc/product/banner_img.png" > -->
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    download() {
      if (this.$route.path != '/download') {
        this.$router.push('/download')
        this.$nextTick(() => {
          document.documentElement.scrollTop = 0
        })
      } else {
        document.documentElement.scrollTop = this.$refs.bannerProductRef.clientHeight - 200
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.animation {
  animation-name: bounceInLeft;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  // animation-delay: 1s;//延迟多久开始播放动画话
}
.img01 {
  animation-name: bounceInDown;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  // animation-delay: 1s;//延迟多久开始播放动画话
}
.img02 {
  animation-name: bounceInRight;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  // animation-delay: 1s;//延迟多久开始播放动画话
}
.shows {
  animation-name: show;
  animation-duration: 4s;
  animation-fill-mode: forwards;
  // animation-delay: 2s;//延迟多久开始播放动画话
}
@keyframes show {
  0% {
    opacity: 0;
  }
  // 10%{opacity: 0; }
  // 25%{ opacity: 0.1; }
  // 50%{opacity: 0; }
  // 75%{opacity: 0; }
  100% {
    opacity: 1;
  }
}
.banner_context {
  // opacity: 0;
}
.product_context {
  height: 27.368421rem;
  padding-top: 6.578947rem;
  box-sizing: border-box;
  background-image: url('~@/assets/img/pc/product/top_banner.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .banner {
    color: white;
    width: 46.842105rem;
    margin: 0 auto;
    height: 15.789473rem;
    display: flex;
    padding-left: 4.868421rem;
    box-sizing: border-box;
    justify-content: space-between;
    .banner_context {
      // opacity: 0;
      .transverse {
        background-color: white;
        border-radius: 0.131578rem;
        width: 3.68421rem;
        height: 0.263157rem;
      }
      h2 {
        font-size: 1.842105rem;
        margin: 1.315789rem 0;
      }
      .context_text {
        font-size: 0.789473rem;
        &::before {
          display: inline-block;
          content: '';
          width: 10px;
          height: 10px;
          background-color: white;
          transform: translateY(-50%);
          margin-right: 0.657894rem;
        }
      }
      .context_img {
        height: 2.105263rem;
        display: flex;
        align-items: center;
        font-size: 0.789473rem;
        img {
          height: 100%;
        }
      }
      .btn {
        width: 7.368421rem;
        height: 2.105263rem;
        border-radius: 1.052631rem;
        border: none;
        color: #7d6bef;
        font-size: 0.842105rem;
        line-height: 2.105263rem;
        margin-top: 1.052631rem;
        cursor: pointer;
      }
    }
    .img {
      height: 10.526315rem;
      align-self: flex-end;
      img {
        height: 100%;
        // opacity: 0;
      }
    }
  }
}
</style>
